/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Collapse, List, ListItemIcon } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { KycStates } from '@ha/api/v2/types/Kyc';
import { ListItemText, theme, ListItemTextProps } from '@hbf/dsl/legacy';
import { ArrowButtonDown, ArrowButtonUp } from '@hbf/icons/streamline-bold';

import { useIntl } from 'ha/i18n';
import { getIsIcelandicLandlord } from 'ha/modules/AuthLogic/selectors';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { MenuItem } from 'ha/components/Menu/MenuItem';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import { KycStatusBadge } from './KycStatusBadge';

const useStyles = makeStyles()({
  verificationItemLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },

  arrowIcon: {
    fontSize: '0.75rem !important',
    color: theme.palette.grey[700],
  },
});

interface Props {
  kycStatus: KycStates | undefined;
}

const WrappedListItemText = (props: ListItemTextProps) => {
  return <ListItemText {...props} style={{ whiteSpace: 'normal' }} />;
};

// TODO: refactor
// eslint-disable-next-line react/function-component-definition
export function NavBarPayoutListItems({ kycStatus }: Props) {
  const { classes } = useStyles();
  const { rebrandTransactionsHistory } = useFeatureFlags();
  const isIcelandicLandlord = useSelector(getIsIcelandicLandlord);

  const { T, urlResolver } = useIntl();

  const [isPaymentSettingsMenuOpen, setIsPaymentSettingsMenuOpen] =
    React.useState(true);

  return (
    <div>
      <Track name="Clicked Payment requests in navigation">
        <MenuItem to={urlResolver.getPayoutsPaymentRequests()}>
          <WrappedListItemText primary={T('payment_requests.page_title')} />
        </MenuItem>
      </Track>

      <Track name="Clicked Transactional history in navigation">
        <MenuItem to={urlResolver.getTransactionHistoryUrl()}>
          <WrappedListItemText
            primary={
              isEnabled(rebrandTransactionsHistory)
                ? T('transactions.page_title')
                : T('transaction_history.page_title')
            }
          />
        </MenuItem>
      </Track>

      <Track name="Clicked Comission plan in navigation">
        <MenuItem to={urlResolver.getCommissionPlanUrl()}>
          <WrappedListItemText primary={T('commission_plan.page_title')} />
        </MenuItem>
      </Track>

      <Track name="Clicked Invoices in navigation">
        <MenuItem to={urlResolver.getInvoicesUrl()}>
          <WrappedListItemText primary={T('invoices.page_title')} />
        </MenuItem>
      </Track>

      <Track name="Clicked Payments settings in navigation (expanding the menu)">
        <MenuItem onClick={() => setIsPaymentSettingsMenuOpen(s => !s)}>
          <WrappedListItemText primary={T('payout_settings.page_title')} />

          <ListItemIcon>
            {isPaymentSettingsMenuOpen ? (
              <ArrowButtonUp className={classes.arrowIcon} />
            ) : (
              <ArrowButtonDown className={classes.arrowIcon} />
            )}
          </ListItemIcon>
        </MenuItem>
      </Track>

      <Collapse in={isPaymentSettingsMenuOpen} style={{ paddingLeft: 16 }}>
        <List component="div" disablePadding>
          <Track name="Clicked Payout methods in navigation">
            <MenuItem to={urlResolver.getPayoutMethodsUrl()}>
              <WrappedListItemText primary={T('payout_methods.page_title')} />
            </MenuItem>
          </Track>

          <Track name="Clicked Billing details in navigation">
            <MenuItem to={urlResolver.getBillingUrl()}>
              <WrappedListItemText
                primary={T('billing_addresses.page_title')}
              />
            </MenuItem>
          </Track>

          {!isIcelandicLandlord && (
            <Track name="Clicked Verify identity in navigation">
              <MenuItem to={urlResolver.getVerifyIdentityUrl()}>
                <WrappedListItemText
                  primary={
                    <div className={classes.verificationItemLabel}>
                      {T('identity_verification.page_title')}
                    </div>
                  }
                />
                <ListItemIcon>
                  {kycStatus && <KycStatusBadge kycStatus={kycStatus} />}
                </ListItemIcon>
              </MenuItem>
            </Track>
          )}
        </List>
      </Collapse>
    </div>
  );
}
