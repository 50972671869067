import React from 'react';

import { useLocation } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { NoSSR } from '@hbf/dsl/legacy';

import { defaultFonts } from 'ha/constants/fonts/default';
import { interFont } from 'ha/constants/fonts/dslFonts';

import { useIntl } from 'ha/i18n';

import type { FontInfo } from 'ha/components/LoadFonts';
import { LoadFonts } from 'ha/components/LoadFonts';
import { LoadableVerifyEmailDialogRoot } from 'ha/modules/ActivationDialogs/VerifyEmailDialog';
import { LoadableAlertbar } from 'ha/modules/Alertbar';
import { FreeBookingsCounterProvider } from 'ha/modules/FreeBookings/context';
import { GoogleOneTap } from 'ha/modules/GoogleOneTap';
import { KrugerDialog } from 'ha/modules/Kruger';
import { NavBarSideContainer as NavBarSide } from 'ha/modules/NavBarSide';
import { HeaderDynamicIsland } from 'ha/modules/Navigation/components/HeaderDynamicIsland/HeaderDynamicIsland';
import { HeaderQuickActions } from 'ha/modules/Navigation/components/HeaderQuickActions/HeaderQuickActions';
import { NavigationFooter } from 'ha/modules/Navigation/NavigationFooter';
import { NavigationHeader } from 'ha/modules/Navigation/NavigationHeader';
import { NavigationHeaderMinimized } from 'ha/modules/Navigation/NavigationHeaderMinimized';
import {
  PageLayoutContext,
  PageLayoutProvider,
} from 'ha/modules/Page/contexts/PageLayoutContext';
import { PageProps } from 'ha/modules/Page/types';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';

import { Favicons } from './Favicons';
import { FixedFooterSection } from './FixedFooterSection';
import { Meta } from './Meta';
import { ProgressBar } from './ProgressBar';
import { StickyFooter } from './StickyFooter';

interface Props {
  activeFonts?: FontInfo[];
  useLegacyFonts?: boolean;
  fixedFooterContent?: React.ReactNode;
  globalBanner?: React.ReactNode;
  hasFooter?: boolean;
  hasHeader?: boolean;
  hasSidebar?: boolean;
  hasProgressBar?: boolean;
  hasStickyNav?: boolean;
  isAuthenticated: boolean;
  pageBanner?: React.ReactNode;
  showBottomNav?: boolean;
  searchBarElement?: React.ReactNode;
  flexContent?: boolean;
  hideCTA?: boolean;
  slots?: PageProps['slots'];
}

const useStyles = makeStyles()(() => ({
  contentContainer: {
    flexGrow: 1,
    width: 0,
  },
  mainContainer: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
  },
}));

export const PageLayout = ({
  activeFonts = [],
  useLegacyFonts = true,
  children,
  fixedFooterContent,
  globalBanner,
  hasFooter = true,
  hasHeader = true,
  hasSidebar = false,
  hasProgressBar = true,
  hasStickyNav = false,
  isAuthenticated,
  pageBanner,
  showBottomNav = true,
  searchBarElement,
  hideCTA = false,
  flexContent = false,
  slots = { Header: <PageLayout.HeaderFull /> },
}: React.PropsWithChildren<Props>) => {
  const { classes, cx } = useStyles();

  const { urlResolver } = useIntl();
  const location = useLocation();

  const theme = useTheme();

  const loadFonts = [
    ...activeFonts,
    ...(useLegacyFonts ? defaultFonts : []),
    interFont,
  ];

  return (
    <StickyFooter>
      <CssBaseline />

      <Meta />
      <LoadFonts activeFonts={loadFonts} />
      <Favicons />
      <NoSSR>
        <LoadableAlertbar />
      </NoSSR>
      {globalBanner}
      {hasProgressBar && <ProgressBar />}
      <PageLayoutProvider
        hasSidebar={hasSidebar}
        hasStickyNav={hasStickyNav}
        searchBarElement={searchBarElement}
        hideCTA={hideCTA}
      >
        <FreeBookingsCounterProvider>
          {hasHeader && slots.Header}
        </FreeBookingsCounterProvider>
      </PageLayoutProvider>

      <div>{pageBanner}</div>

      <div className={cx(classes.mainContainer, classes.flex)}>
        {hasSidebar && <NavBarSide />}
        <div
          className={cx(classes.contentContainer, {
            [classes.flex]: flexContent,
          })}
        >
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </div>
      </div>

      {hasFooter && (
        <RebrandThemeProvider>
          <NavigationFooter />
        </RebrandThemeProvider>
      )}
      <NoSSR>
        <FixedFooterSection
          fixedFooterContent={fixedFooterContent}
          isAuthenticated={isAuthenticated}
          showBottomNav={showBottomNav}
        />
      </NoSSR>
      {urlResolver.isOnAccountPages(location.pathname) && (
        <NoSSR>
          <LoadableVerifyEmailDialogRoot />
        </NoSSR>
      )}
      <NoSSR>
        <KrugerDialog />
      </NoSSR>
      <NoSSR>
        <GoogleOneTap />
      </NoSSR>
    </StickyFooter>
  );
};

const PageLayoutHeaderMinimized = ({
  goBackButton,
}: {
  goBackButton: React.ReactNode;
}) => {
  const { hasStickyNav } = PageLayoutContext.useContext();

  return (
    <RebrandThemeProvider>
      <NavigationHeaderMinimized
        sticky={hasStickyNav}
        slots={{
          goBackButton,
        }}
      />
    </RebrandThemeProvider>
  );
};

const PageLayoutHeaderFull = () => {
  const { hasStickyNav, hasSidebar, searchBarElement, hideCTA } =
    PageLayoutContext.useContext();
  const value = React.useMemo(
    () => ({ hasSidebar: !!hasSidebar, showCTA: !hideCTA }),
    [hasSidebar, hideCTA],
  );
  return (
    <RebrandThemeProvider>
      <NavigationHeader
        sticky={hasStickyNav}
        slots={{
          dynamicIsland: searchBarElement ? (
            <HeaderDynamicIsland>{searchBarElement}</HeaderDynamicIsland>
          ) : undefined,
          quickActions: (
            <HeaderQuickActions.Context.Provider value={value}>
              <NavigationHeader.DefaultQuickActions />
            </HeaderQuickActions.Context.Provider>
          ),
        }}
      />
    </RebrandThemeProvider>
  );
};

PageLayout.HeaderMinimized = PageLayoutHeaderMinimized;
PageLayout.HeaderFull = PageLayoutHeaderFull;
