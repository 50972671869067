import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { isContentfulSupported } from '@ha/contentful';
import { IconButton, Link, Typography, units } from '@hbf/dsl/core';
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from '@hbf/icons/brand-bold';

import { useIntl } from 'ha/i18n';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import { LanguageSwitcher } from '../LanguageSwitcher/components/LanguageSwitcher';

import { HousingAnywhereLogo } from './components/NavigationLogo/NavigationLogo';

const NO_FOLLOW_REL = 'nofollow noopener noreferrer';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7),
    justifyContent: 'space-between',
    background: theme.palette.mono.light,
    padding: theme.spacing(7, 5),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(7),
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(9), // 20
    },
  },
  logoAndLanguageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(7),
  },
  logo: {
    fill: theme.palette.secondary.main,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(8),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  linkSectionColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    width: units.pxToRem(180),
  },
  linkSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },
  linkSectionLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(5),
  },
  socialContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  socialIcon: {
    color: theme.palette.secondary.main,
  },
}));

const SocialLink = ({
  socialVendor,
  href,
  children,
}: React.PropsWithChildren<{ socialVendor: string; href: string }>) => {
  const { classes } = useStyles();

  return (
    <Track name="Clicked Social icon" type="icon" socialvendor={socialVendor}>
      <IconButton
        component="a"
        className={classes.socialIcon}
        href={href}
        target="_blank"
        rel={NO_FOLLOW_REL}
        title={socialVendor}
      >
        {children}
      </IconButton>
    </Track>
  );
};

interface OwnLinkProps {
  name: string;
}

const OwnLink = ({
  name,
  children,
  ...rest
}: Omit<React.ComponentProps<typeof Link>, 'size'> & OwnLinkProps) => {
  return (
    <Track name={`Clicked ${name} in footer`} type="link">
      <Link size="sm" {...rest}>
        {children}
      </Link>
    </Track>
  );
};

const OwnHousingAnywhereLinks = () => {
  const { T, urlResolver, lang } = useIntl();

  const isSupportedLanguage = isContentfulSupported(lang);

  const { classes } = useStyles();

  return (
    <div className={classes.linkSectionContainer}>
      <Typography variant="body/sm-semibold">{T('nav.footer.ha')}</Typography>

      <div className={classes.linkSectionLinks}>
        <OwnLink name="About" href={urlResolver.getAboutUrl()}>
          {T('nav.footer.about')}
        </OwnLink>
        <OwnLink name="Careers" href={urlResolver.getCareersUrl()}>
          {T('nav.footer.careers')}
        </OwnLink>
        {isSupportedLanguage && (
          <OwnLink name="Press" href={urlResolver.getPressUrl()}>
            {T('nav.footer.press')}
          </OwnLink>
        )}

        <OwnLink
          name="Become a partner plans"
          href={urlResolver.getPartnershipsUrl()}
          rel={NO_FOLLOW_REL}
        >
          {T('nav.footer.partners')}
        </OwnLink>
        <OwnLink
          name="Terms and conditions"
          href={urlResolver.getTermsUrl()}
          rel={NO_FOLLOW_REL}
        >
          {T('nav.footer.terms')}
        </OwnLink>
        <OwnLink
          name="Privacy Policy"
          href={urlResolver.getPrivacyPolicyUrl()}
          rel={NO_FOLLOW_REL}
        >
          {T('nav.footer.privacy')}
        </OwnLink>
        <OwnLink
          name="Cookie Policy"
          href={urlResolver.getCookiePolicyUrl()}
          rel={NO_FOLLOW_REL}
        >
          {T('nav.footer.cookie')}
        </OwnLink>
        <OwnLink
          name="Sitemap"
          href={urlResolver.getSitemapUrl()}
          rel={NO_FOLLOW_REL}
        >
          {T('nav.footer.sitemap')}
        </OwnLink>
      </div>
    </div>
  );
};

const OwnTenantLinks = () => {
  const { T, urlResolver } = useIntl();

  const { classes } = useStyles();

  return (
    <div className={classes.linkSectionContainer}>
      <Typography variant="body/sm-semibold">
        {T('nav.footer.tenants')}
      </Typography>

      <div className={classes.linkSectionLinks}>
        <OwnLink name="How to rent" href={urlResolver.getRenting()}>
          {T('nav.footer.how_it_works')}
        </OwnLink>
        <OwnLink
          name="Pricing tenants"
          href={urlResolver.getPricingTenantsUrl()}
        >
          {T('nav.footer.pricing')}
        </OwnLink>
        <OwnLink name="Payments tenants" href={urlResolver.getPaymentsTenant()}>
          {T('nav.footer.pay_rent')}
        </OwnLink>

        <OwnLink
          name="Tenants blog"
          href={urlResolver.getTenantsGlobalBlogUrl()}
        >
          {T('nav.footer.tenants_blog')}
        </OwnLink>
      </div>
    </div>
  );
};

const OwnSupportLinks = () => {
  const { T, urlResolver } = useIntl();

  const { classes } = useStyles();

  return (
    <div className={classes.linkSectionContainer}>
      <Typography variant="body/sm-semibold">
        {T('nav.footer.support')}
      </Typography>

      <div className={classes.linkSectionLinks}>
        <div className={classes.linkSectionLinks}>
          <OwnLink name="Help Center" href={urlResolver.getAnswersUrl()}>
            {T('nav.footer.help')}
          </OwnLink>

          <OwnLink name="Contact us" href={urlResolver.getContactUrl()}>
            {T('nav.footer.contact')}
          </OwnLink>
        </div>
      </div>
    </div>
  );
};

const OwnAdvertiserLinks = () => {
  const { T, urlResolver, lang } = useIntl();

  const { classes } = useStyles();

  return (
    <div className={classes.linkSectionContainer}>
      <Typography variant="body/sm-semibold">
        {T('nav.footer.landlords')}
      </Typography>

      <div className={classes.linkSectionLinks}>
        <OwnLink name="How to rent out" href={urlResolver.getRentingOut()}>
          {T('nav.footer.how_it_works')}
        </OwnLink>
        <OwnLink
          name="Pricing landlords"
          href={urlResolver.getPricingLandlordsUrl()}
        >
          {T('nav.footer.pricing')}
        </OwnLink>
        <OwnLink
          name="Become an advertiser"
          href={urlResolver.getPrivateLandlordLandingPageUrl()}
        >
          {T('nav.footer.become_landlord')}
        </OwnLink>
        <OwnLink
          name="Payments landlords"
          href={urlResolver.getPaymentsLandlord()}
        >
          {T('nav.footer.collect_rent')}
        </OwnLink>
        <OwnLink name="Help Center" href={urlResolver.getHelpCenterUrl(lang)}>
          {T('nav.footer.how_to_guides')}
        </OwnLink>
        {urlResolver.hasLandlordsBlogUrl(lang) && (
          <OwnLink
            name="Blog for landlords"
            href={urlResolver.getLandlordsBlogUrl(lang)}
          >
            {T('nav.footer.landlord_blog')}
          </OwnLink>
        )}
        <OwnLink
          name="RentRadar"
          href={urlResolver.getRentRadarUrl()}
          rel={NO_FOLLOW_REL}
          target="_blank"
        >
          {T('nav.footer.rentradar')}
        </OwnLink>
        <OwnLink name="Integrations" href={urlResolver.getIntegrationsUrl()}>
          {T('nav.footer.integrations')}
        </OwnLink>
        <OwnLink
          name="Legal rental information"
          href={urlResolver.getLegalUrl()}
          rel={NO_FOLLOW_REL}
        >
          {T('nav.footer.sample_contracts')}
        </OwnLink>
      </div>
    </div>
  );
};

const SocialLinks = () => {
  const { urlResolver } = useIntl();

  return (
    <React.Fragment>
      <SocialLink socialVendor="facebook" href={urlResolver.getFacebookUrl()}>
        <Facebook />
      </SocialLink>
      <SocialLink socialVendor="twitter" href={urlResolver.getTwitterUrl()}>
        <Twitter />
      </SocialLink>
      <SocialLink socialVendor="linkedin" href={urlResolver.getLinkedinUrl()}>
        <Linkedin />
      </SocialLink>
      <SocialLink socialVendor="youtube" href={urlResolver.getYoutubeUrl()}>
        <Youtube />
      </SocialLink>
      <SocialLink socialVendor="instagram" href={urlResolver.getInstagramUrl()}>
        <Instagram />
      </SocialLink>
    </React.Fragment>
  );
};

export const NavigationFooter = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} data-test-locator="Navigation-Footer">
      <div className={classes.logoAndLanguageContainer}>
        <div>
          <HousingAnywhereLogo
            size="full"
            color="secondary"
            className={classes.logo}
          />
        </div>

        <LanguageSwitcher size="full-secondary" />
      </div>

      <div className={classes.linkContainer}>
        <div className={classes.linkSectionColumn}>
          <OwnHousingAnywhereLinks />
        </div>

        <div className={classes.linkSectionColumn}>
          <OwnTenantLinks />
          <OwnSupportLinks />
        </div>

        <div className={classes.linkSectionColumn}>
          <OwnAdvertiserLinks />
        </div>
      </div>

      <div className={classes.socialContainer}>
        <SocialLinks />
      </div>
    </div>
  );
};
